import $ from 'jquery'
import URI from 'urijs'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['container', 'input', 'url', 'preview', 'progress', 'destroy']

  set progress(progress) { $(this.progressTarget).css('width', `${Math.ceil(progress * 100)}%`)         }
  
  get files()            { return this.inputTarget.files                                                }
  get presignedFields()  { return Object.entries(JSON.parse(this.data.get('presigned-fields') || '{}')) }
  get presignedURL()     { return this.data.get('presigned-url')                                        }

  connect() {
    this.inputTarget.multiple = true
  }

  upload(event) {
    const files = Array.from(this.files || [])
    if (!files.length) return

    files.forEach(file => this.createPreviewBlock(file))

    this.inputTarget.value = ''
  }

  createPreviewBlock(file) {
    const block = document.createElement('div')
    block.classList.add('gallery-item')

    block.innerHTML = `
      <div class="file-preview">
        <img src="" alt="preview" />
      </div>
      <input type="hidden" name="review[uploaded_photo_urls][]" value="">
      <button type="button" class="delete-label"></button>
      <div class="upload-progress">
        <span data-target="progress" class="upload-progress-bar" style="width: 0%;"></span>
      </div>
    `

    this.containerTarget.appendChild(block)
    this.showLocalPreview(file, block.querySelector('img'))
    block.querySelector('.delete-label').addEventListener('click', () => block.remove())

    this.uploadToS3(file, block)
  }

  showLocalPreview(file, imgEl) {
    const reader = new FileReader()
    reader.onload = e => {
      imgEl.src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  uploadToS3(file, block) {
    const formData = new FormData()
    this.presignedFields.forEach(([k, v]) => formData.append(k, v))
    formData.append('file', file)

    block.classList.add('is-uploading')

    $.ajax({
      url:         this.presignedURL,
      method:      'POST',
      data:        formData,
      dataType:    'xml',
      contentType: false,
      processData: false,
      xhr:         () => this.createXHR(block),
      success:     (resp) => this.onUploadSuccess(resp, block),
      error:       () => this.onUploadError(block)
    })
  }

  createXHR(block) {
    const xhr = $.ajaxSettings.xhr()

    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        const pct = Math.round((e.loaded / e.total) * 100)
        block.querySelector('.upload-progress-bar').style.width = `${pct}%`
      }
    }, false)

    return xhr
  }

  onUploadSuccess(response, block) {
    const locationNode = response.getElementsByTagName('Location')[0]
    if (locationNode) {
      let url = locationNode.textContent
      url = decodeURIComponent(url.replace(/%2F/g, '/'))
      block.querySelector('input[type="hidden"]').value = url
    }

    block.classList.remove('is-uploading')
    block.classList.add('is-uploaded')
  }

  onUploadError(block) {
    block.classList.remove('is-uploading')
    block.classList.add('upload-error')
  }
}
