import _ from 'underscore'
import ApplicationController from './application_controller'

require('../lib/polyfills/closest')

const ESC_KEYCODE   = 27
const LEFT_KEYCODE  = 37
const RIGHT_KEYCODE = 39

var focusedElementBeforeDialogOpened;

export default class extends ApplicationController {

  static targets = [ 'container', 'modal', 'close', 'content' ]

  get photoElement () {
    return this.opener.closest('.lightbox-photo')
  }

  get previousSibling () {
    if (this.photoElement) {
      let sibling = this.photoElement.previousElementSibling
      if (sibling && sibling.classList.contains('lightbox-photo')) {
        return sibling
      }
    }
  }

  get nextSibling () {
    if (this.photoElement) {
      let sibling = this.photoElement.nextElementSibling
      if (sibling && sibling.classList.contains('lightbox-photo')) {
        return sibling
      }
    }
  }

  connect () {
    this.body   = this.element
    this.opened = false
    this.opener = null

    if (!this.hasModalTarget) {
      this.body.innerHTML += `
        <div class="modal" role="dialog" aria-modal="true" tabindex="-1" data-${this.identifier}-target="modal" data-action="keydown@window->modal#handleKeyEvent">
          <div class="modal-header">
            <a href="#" class="modal-close close-modal" data-${this.identifier}-target="close" data-action="modal#close" autofocus>Close</a>
          </div>
          <div class="modal-overlay" data-action="click->modal#close">
            <div class="modal-spinner"></div>
          </div>
          <div class="modal-content" data-${this.identifier}-target="content" id="modal_content">
          </div>
        </div>
      `
    }
  }

  handleKeyEvent (event) {
    if (this.opened) {
      switch (event.keyCode) {
        case ESC_KEYCODE:
          this.close(event)
          break

        case LEFT_KEYCODE:
          this.previous(event)
          break

        case RIGHT_KEYCODE:
          this.next(event)
          break
      }
    }
  }

  previous (event) {
    if (this.previousSibling) {
      event.preventDefault()
      this.previousSibling.querySelector('a[data-action="modal#open"]').click()
    }
  }

  next (event) {
    if (this.nextSibling) {
      event.preventDefault()
      this.nextSibling.querySelector('a[data-action="modal#open"]').click()
    }
  }

  showInFrame (event) {
    let target = event.currentTarget
    if (target && target['href'].length) {
      let url = target['href']

      if (url.split('?').length > 1) {
        url = url + '&embed=true'
      } else {
        url = url + '?embed=true'
      }

      event.preventDefault()

      this.show(`
        <iframe src="${url}" class="modal-iframe">
        </iframe>
      `)
    }
  }

  show (html) {
    this.contentTarget.innerHTML = html
    if (!this.opened) this.open()

    this.detectImageOrientation()
  }

  detectImageOrientation () {
    if (this.opener.dataset.modalVariant === 'lightbox') {
      let imageWrapper = this.modalTarget.querySelector('.lightbox-photo-wrapper')
      let image        = imageWrapper.querySelector('img')

      if (image.classList.contains('photo-orientation-unknown') || !image.hasAttribute('class')) {
        let imageClone = image.cloneNode(true)

        image.remove()
        imageClone.classList.remove(...imageClone.classList)
        imageWrapper.appendChild(imageClone)

        imageClone.addEventListener("load", event => {
          let imageWidth  = imageClone.naturalWidth
          let imageHeight = imageClone.naturalHeight

          if (imageWidth > imageHeight) {
            imageClone.classList.add('photo-orientation-landscape')
          } else if (imageWidth < imageHeight) {
            imageClone.classList.add('photo-orientation-portrait')
          }
        })
      }
    }
  }

  open (event) {
    this.body.classList.add('modal-is-open')
    this.containerTarget.setAttribute("aria-hidden", "true")

    focusedElementBeforeDialogOpened = document.activeElement;

    this.closeTarget.focus()

    this.opened = true

    if (event) {
      this.opener = event.currentTarget

      event.preventDefault()

      if (this.opener.dataset.modalVariant) {
        this.modalTarget.classList.add(this.opener.dataset.modalVariant)
      }

      if (this.opener.dataset.modalVariant === 'lightbox') {
        let previousLightboxArrow = '<a href="#" class="lightbox-prev" data-action="click->modal#previous"></a>'
        let nextLightboxArrow     = '<a href="#" class="lightbox-next" data-action="click->modal#next"></a>'

        this.modalTarget.querySelectorAll('.lightbox-prev').forEach(e => e.parentNode.removeChild(e))
        this.modalTarget.querySelectorAll('.lightbox-next').forEach(e => e.parentNode.removeChild(e))

        if (this.previousSibling) {
          this.modalTarget.insertAdjacentHTML('beforeend', previousLightboxArrow)
        }

        if (this.nextSibling) {
          this.modalTarget.insertAdjacentHTML('beforeend', nextLightboxArrow)
        }
      }
    } else {
      this.opener = null
    }
  }

  close (event) {
    if (event) {
      event.preventDefault()
    }

    this.body.classList.remove('modal-is-open')
    this.containerTarget.removeAttribute("aria-hidden")
    this.modalTarget.setAttribute("class", "modal")

    if (document.querySelector('.lightbox-prev')) {
      document.querySelector('.lightbox-prev').remove()
    }

    if (document.querySelector('.lightbox-next')) {
      document.querySelector('.lightbox-next').remove()
    }

    focusedElementBeforeDialogOpened.focus();

    this.opened   = false
    this.opener   = null
    this.contentTarget.innerHTML = ''
  }
}
